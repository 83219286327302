import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Button, DataLayer } from 'swp-components/lib/components/common'
import DataLayerScope from 'swp-components/lib/components/common/data-layer-scope'
import Footer from 'swp-components/lib/components/footer'
import {
  PackageDetailCard,
  TermsButton,
  TermsModal,
  ViewallButton,
} from 'swp-components/lib/components/internet-package'
import ListContent from 'swp-components/lib/components/list-content'
import Section from 'swp-components/lib/components/section'
import { DataLayerProvider } from 'swp-components/lib/contexts/data-layer'

import BottomBanner from '@/components/bottom-banner'
import ChatShop from '@/components/chat-shop'
import CookiesConsent from '@/components/cookies-consent'
import Header from '@/components/header'
import InternetExtraPackage from '@/components/internet-extra-package'
import InternetPackageDetail from '@/components/internet-package-detail'
import InternetPackageTerms from '@/components/internet-package-terms'
import Newrelic from '@/components/newrelic-agent'
import PrivilegeShelf from '@/components/privilege-shelf'
import SectionHeading from '@/components/section-heading'
import Seo from '@/components/seo'
import TopBanner from '@/components/top-banner'
import useTrueOnlinePageDataLayer from '@/hooks/data-layers/use-true-online-page'
import NotFoundPage from '@/pages/404'
import strapi from '@/strapi'

const TrueOnlinePackagePage = ({ serverData }) => {
  let [
    pack,
    headerScript,
    brandHeaders,
    productServices,
    tolContent,
    footerHeaders,
    chatShop,
    contactWithSaleContent,
    mariConfig,
  ] = strapi.parseContents(serverData.contents)
  let intl = useIntl()
  const [showTerms, setShowTerms] = useState(false)
  const [showPrivilegeViewMore, setShowPrivilegeViewMore] = useState(false)
  const [showPrivilegeActive, setShowPrivilegeActive] = useState(false)
  const [privilegeList, setPrivilegeList] = useState([])
  const [locationSearch, setLocationSearch] = useState('');

  let { handleMessage, createScopeProps } = useTrueOnlinePageDataLayer()
  let packageOption = pack.packageOption
  let packageType = pack.packageOption.tolPackageType
  useEffect(() => {
    let packageOption = pack.packageOption
    if (packageOption.privileges.length > 5) {
      setShowPrivilegeViewMore(true)
    }
    setPrivilegeList(packageOption.privileges.slice(0, 5))

    // const searchParams = new URLSearchParams(window.location.search);
    // console.log(window.location.search);
    if (typeof window !== 'undefined') {
      const locationSearch = window.location.search;
      if (locationSearch) {
        setLocationSearch(locationSearch.substring(1));
      }
    }
  }, [])

  if (!pack) {
    return <NotFoundPage />
  }

  const clickContactModal = () => {}

  const submitContactModal = () => {}

  return (
    <>
      <Newrelic />
      <DataLayerProvider handler={handleMessage}>
        <Seo seo={pack.seo} script={headerScript.script} />
        <DataLayerScope {...createScopeProps('Header')}>
          <Header items={brandHeaders} services={productServices} />
        </DataLayerScope>
        {packageType.topBanners.length > 0 && (
          <DataLayerScope
            {...createScopeProps('TopBannerPackageDetail', {
              topBanners: packageType.topBanners,
            })}
          >
            <TopBanner banners={packageType.topBanners} />
          </DataLayerScope>
        )}
        <div className="mt-4 mt-md-5">
          <SectionHeading tag="h1" level="1" variant="underline">
            {packageType.name}
          </SectionHeading>
          <SectionHeading className="mt-1 mb-3" level="2">
            {packageType.detail}
          </SectionHeading>
          <div className="mt-1 mb-3">
            <DataLayerScope
              {...createScopeProps('InternetPackageDetail', { pack })}
            >
              <PackageDetailCard pack={pack} />
            </DataLayerScope>
          </div>
        </div>
        <div className="text-center mt-4">
          <DataLayerScope
            {...createScopeProps('InternetPackageDetail', {
              pack,
            })}
          >
            <DataLayer
              selector={`sendLeadButton`}
              triggers={['click']}
              render={ref => (
                <Button
                  className="mx-1"
                  ref={ref}
                  variant="pinkgrad"
                  link={{
                    url: pack.serviceType
                      ? locationSearch == ''
                        ? pack.serviceType.serviceButton.url + '?id=' + pack.id
                        : pack.serviceType.serviceButton.url + '?id=' + pack.id + '&' + locationSearch
                      : locationSearch == ''
                        ? 'https://www.true.th/store/trueonline/package/form-contact?id=' + pack.id
                        : 'https://www.true.th/store/trueonline/package/form-contact?id=' + pack.id + '&' + locationSearch,
                    newTab: pack.serviceType
                      ? pack.serviceType.serviceButton.newTab
                      : true,
                  }}
                >
                  {pack.serviceType
                    ? pack.serviceType.serviceButton.buttonOnModal
                    : pack.locale === 'th'
                    ? 'ให้เจ้าหน้าที่ติดต่อกลับ'
                    : 'Leave your information'}
                </Button>
              )}
            />
          </DataLayerScope>
        </div>
        {packageOption.tolBundles.length > 0 && (
          <div className="mt-3 mt-md-4">
            {packageOption.bundleHeader && (
              <>
                <SectionHeading variant="underline" tag="h2">
                  {packageOption.bundleHeader.name}
                </SectionHeading>
                <SectionHeading className="mt-3 mb-4" level="2">
                  {packageOption.bundleHeader.detail}
                </SectionHeading>
              </>
            )}
            <DataLayerScope
              {...createScopeProps('PrivilegePackageDetail', {
                privileges: packageOption.tolBundles,
                header: packageOption.bundleHeader,
              })}
            >
              <PrivilegeShelf
                color="blue"
                privileges={packageOption.tolBundles}
                preferWidth={200}
              />
            </DataLayerScope>
          </div>
        )}
        {packageOption.privileges.length > 0 && (
          <div
            className="mt-4 mt-md-5 mx-auto"
            style={{ maxWidth: '560px', width: '100%' }}
          >
            {packageOption.privilegesHeader && (
              <>
                <SectionHeading variant="underline" tag="h2">
                  {packageOption.privilegesHeader.name}
                </SectionHeading>
                <SectionHeading className="mt-3 mb-4" level="2">
                  {packageOption.privilegesHeader.detail}
                </SectionHeading>
              </>
            )}
            <ListContent.Columns
              color="blue"
              columns={1}
              items={privilegeList}
            />
            {showPrivilegeViewMore && (
              <div className="d-flex justify-content-center mt-2">
                <DataLayerScope
                  {...createScopeProps('PrivilegeViewMore', {
                    pack,
                    packageType: packageType,
                    button: showPrivilegeActive
                      ? intl.formatMessage({
                          id: 'InternetPackage.showLessButton',
                        })
                      : intl.formatMessage({
                          id: 'InternetPackage.showAllButton',
                        }),
                  })}
                >
                  <ViewallButton
                    onAction={action => {
                      if (showPrivilegeActive) {
                        setPrivilegeList(packageOption.privileges.slice(0, 5))
                      } else {
                        setPrivilegeList(packageOption.privileges)
                      }

                      setShowPrivilegeActive(!showPrivilegeActive)
                    }}
                    active={showPrivilegeActive}
                  />
                </DataLayerScope>
              </div>
            )}
          </div>
        )}
        <div className="text-center mt-4">
          <DataLayerScope
            {...createScopeProps('TermsPackageDetail', {
              pack: pack,
              packageType: packageType,
            })}
          >
            <TermsButton
              pack={pack}
              onAction={action => {
                if (action === 'show-terms') {
                  setShowTerms(true)
                }
              }}
            />
          </DataLayerScope>
        </div>

        <TermsModal
          pack={pack}
          show={showTerms}
          onAction={action => {
            if (action === 'close-modal') {
              setShowTerms(false)
            }
          }}
        />
        {footerHeaders && (
          <Footer className="pb-4 mt-4" items={footerHeaders} />
        )}
        {chatShop && (
          <ChatShop
            content={chatShop}
            mariPlatform={ serverData.locale == 'th' ? "mari_tol" : "trueonline" }
            mariConfig={mariConfig}
          />
        )}
        <CookiesConsent />
      </DataLayerProvider>
    </>
  )
}

export default TrueOnlinePackagePage

export const getServerData = async ({ params, pageContext }) => {
  let contents = await strapi.getContents(
    [
      `tol-packages/${params.slug}`,
      'header-script',
      'brand-headers',
      'product-services',
      'tol-package-box',
      'footer-headers',
      'chat-and-shop',
      'tol-chat-with-sale-staff',
      'mari-chat-and-shop-config',
    ],
    pageContext.locale
  )

  return {
    props: { contents, locale: pageContext.locale },
  }
}
